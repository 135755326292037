import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Spinner } from "react-bootstrap";
import CallApplicationWidget from "components/dashboards/SEDRA3/CallApplicationWidget";
import { useGetFilteredCallApplicationsQuery } from "components/services/call-application/CallApplicationService";
import {
  useGetApplicationsQuery,
  useGetApplicationByIdQuery,
} from "components/services/application/ApplicationService";
import ActiveCases from "components/dashboards/SEDRA3/ActiveCasesTable";
import ApplicationForm from "components/dashboards/SEDRA3/ApplicationForm";

const CallsForApplicationPage = ({ itemsPerPage }) => {
  const { user } = useSelector((state) => state.auth);

  // Extract roles
  const roles = user?.roles?.map((role) => role.name) || [];

  const [currentPage, setCurrentPage] = useState(1);
  const [applications, setApplications] = useState([]);
  const [callApplications, setCallApplications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false); // New state for create mode
  const [initialData, setInitialData] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const [callApplicationId, setCallApplicationId] = useState(null);
  const [refetchCount, setRefetchCount] = useState(0);
  const [submittedApplications, setSubmittedApplications] = useState([]); // Track submitted applications

  // Fetch call applications
  const {
    data: responseData,
    isLoading: isLoadingCallApplications,
    error: errorCallApplications,
    refetch: refetchCallApplications,
  } = useGetFilteredCallApplicationsQuery({
    bank_id: user.bank.id,
    filterEndDateNull: true,
    page: currentPage,
    size: itemsPerPage,
  });

  // Fetch applications for the bank
  const {
    data: applicationsData,
    isLoading: isLoadingApplications,
    error: errorApplications,
    refetch: refetchApplications,
  } = useGetApplicationsQuery({ bank_id: user.bank.id });

  const { data: applicationData, refetch: refetchApplicationData } = useGetApplicationByIdQuery(
    applicationId,
    {
      skip: !applicationId,
    }
  );

  useEffect(() => {
    if (applicationsData?.data) {
      setApplications(applicationsData.data);
    }
    if (applicationData) {
      setInitialData(applicationData.data);
    }
  }, [applicationsData, applicationData]);

  useEffect(() => {
    if (responseData?.data.items) {
      setCallApplications(responseData.data.items);
    }
  }, [responseData, refetchCount]);

  const handleCloseModal = () => {
    setShowModal(false);
    setApplicationId(null); // Reset applicationId
    setIsCreate(false); // Reset isCreate mode
  };

  const handleCreate = (appId, status) => {
    if (refetchApplications) {
      refetchApplications();
    }
    if (refetchCallApplications) {
      refetchCallApplications();
    }
    setRefetchCount((prevCount) => prevCount + 1);
    setCallApplications((prev) => prev.filter((app) => app.id !== callApplicationId));

    if (applicationId && refetchApplicationData) {
      refetchApplicationData();
    }

    if (status === "Submitted") {
      setSubmittedApplications((prev) => [...prev, appId]);
    }

    setShowModal(false);
  };

  const handleEdit = (id, callId) => {
    setApplicationId(id);
    setCallApplicationId(callId);
    setIsEdit(true);
    setIsCreate(false);
    setShowModal(true);
  };

  const handleDetail = (id, callId) => {
    setApplicationId(id);
    setCallApplicationId(callId);
    setIsEdit(false);
    setIsCreate(false);
    setShowModal(true);
  };

  const handleOpenModal = (id, callId, isEditMode) => {
    setApplicationId(id);
    setCallApplicationId(callId);
    setIsEdit(isEditMode);
    setIsCreate(!isEditMode); // Create mode if not edit mode
    setShowModal(true);
  };

  if (isLoadingCallApplications || isLoadingApplications) {
    return <Spinner animation="border" />;
  }

  if (errorCallApplications) {
    return <div>Error loading call applications</div>;
  }

  if (errorApplications) {
    return <div>Error loading applications</div>;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = callApplications.slice(startIndex, endIndex);

  return (
    <div>
      <Row className="g-3">
        {currentItems.map((item, index) => (
          <Col md={6} key={index}>
            <CallApplicationWidget
              callId={item.id}
              title={"Call for Applications"}
              minAmount={item.min_amnt}
              maxAmount={item.max_amnt}
              maturity={item.maturity}
              grace={item.grace}
              endDate={item.end_date}
              amountClassName="fs-7"
              openApplicationForm={(callId) => handleOpenModal(null, callId, false)} // Trigger modal opening
              roles={roles}
            />
          </Col>
        ))}
      </Row>
      {applications.length > 0 && (
        <div className="mt-4">
          <ActiveCases
            key={applications.length}
            applications={applications}
            onEdit={(id, callId) => handleEdit(id, callId)}
            onDetail={(id, callId) => handleDetail(id, callId)} // Pass handleDetail to ActiveCases
            refetchApplications={refetchApplications}
            refetchCallApplications={() => {
              refetchCallApplications();
              setRefetchCount((prevCount) => prevCount + 1);
            }}
            submittedApplications={applications
              .filter((app) => app.status === "Submitted")
              .map((app) => app.id)} // Only pass submitted applications
            roles={roles}
          />
        </div>
      )}
      <ApplicationForm
        show={showModal}
        handleClose={handleCloseModal}
        onApplicationCreated={handleCreate}
        initialData={initialData}
        isEdit={isEdit}
        isCreate={isCreate} // Pass the isCreate prop to ApplicationForm
        callId={callApplicationId}
        isDetail={!isEdit && !isCreate} // Pass isDetail based on isEdit and isCreate states
      />
    </div>
  );
};

export default CallsForApplicationPage;
