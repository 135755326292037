import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Badge } from "react-bootstrap";
import classNames from "classnames";
import { VIEWER_OF_APPLICATIONS } from "features/constants/roles";

const CallApplicationWidget = ({
  callId,
  title,
  minAmount,
  maxAmount,
  maturity,
  grace,
  endDate,
  amountClassName,
  openApplicationForm, // Prop to trigger opening the form
  roles, // Receive roles as a prop
}) => {
  const formatMonths = (number) => {
    return `${number} month${number !== 1 ? "s" : ""}`;
  };

  const isViewer = roles.includes(VIEWER_OF_APPLICATIONS);

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h5 className="mb-0 mt-2">{title}</h5>
      </Card.Header>
      <Card.Body>
        <div style={{ fontSize: "1rem", lineHeight: "1.2" }}>
          <h2
            className={classNames(amountClassName, "d-inline-block mb-2 text-700 fw-normal lh-1")}
          >
            Up to: NRP {maxAmount}
          </h2>
          <p className="mb-1">Minimum amount: {minAmount}</p>
          <p className="mb-1">
            <Badge
              pill
              bg="warning"
              className="badge-text-style"
            >{`Submission deadline: ${endDate}`}</Badge>
          </p>
          <p className="mb-1">Tenor: {formatMonths(maturity)}</p>
          <p className="mb-1">Grace period: {formatMonths(grace)}</p>
        </div>
        <div className="d-flex justify-content-end">
          <Button variant="primary" onClick={() => openApplicationForm(callId)} disabled={isViewer}>
            Apply
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

CallApplicationWidget.propTypes = {
  title: PropTypes.string.isRequired,
  minAmount: PropTypes.number.isRequired,
  maxAmount: PropTypes.number.isRequired,
  maturity: PropTypes.number.isRequired,
  grace: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  amountClassName: PropTypes.string,
  openApplicationForm: PropTypes.func.isRequired, // New prop for triggering form
  roles: PropTypes.array.isRequired,
};

export default CallApplicationWidget;
