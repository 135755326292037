import React, { useMemo, useState, useEffect } from "react";
import {
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  Form,
  Tooltip,
  OverlayTrigger,
  Dropdown,
  Table,
} from "react-bootstrap";
import SubtleBadge from "components/common/SubtleBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import FalconComponentCard from "components/common/FalconComponentCard";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useGetCallApplicationsQuery,
  useUpdateCallApplicationMutation,
  useCreateCallApplicationMutation,
  useUpdateCallApplicationStatusMutation,
} from "components/services/call-application/CallApplicationService";
import { CalendarDaysIcon } from "svgs/CalendarDays";
import { formatDate } from "components/utilities/sedra3/formatDate";
import {
  useCreateReportScheduleMutation,
  useGetReportScheduleForCallQuery,
  useUpdateReportScheduleMutation,
} from "components/services/report-schedule/reportScheduleService";

const CallApplication = () => {
  // State variables
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentApplication, setCurrentApplication] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [showTableModal, setShowTableModal] = useState(false);

  const [statusUpdateTaskId, setStatusUpdateTaskId] = useState(null); // Track task ID
  const [taskStatus, setTaskStatus] = useState(null); // WebSocket task status

  // Variables for labels and messages
  const modelName = "Calls for Application";
  const modalTitle = isEditMode ? `Edit ${modelName}` : `New ${modelName}`;
  const callApplicationStatus = "Status";
  const id = "ID";
  const startDateLabel = "Start Date";
  const endDateLabel = "Submission";
  const minAmountLabel = "Min Amount";
  const maxAmountLabel = "Max Amount";
  const amountLabel = "Amount";
  const currencyLabel = "Currency";
  const loanTenor = "Loan tenor";
  const graceLabel = "Grace, months";
  const buttonText = "Save Changes";
  const closeButtonText = "Close";
  const newButtonText = "New";
  const editTooltipText = "Edit";
  const loadingText = `Error loading ${modelName}s`;
  const successUpdateMessage = `${modelName} updated successfully.`;
  const successCreateMessage = `${modelName} created successfully.`;
  const errorMessage = `Failed to ${isEditMode ? "update" : "create"} ${modelName}:`;

  const { data: responseData, error, isLoading, refetch } = useGetCallApplicationsQuery();
  const [updateCallApplication, { isLoading: isUpdating }] = useUpdateCallApplicationMutation();
  const [createCallApplication, { isLoading: isCreating }] = useCreateCallApplicationMutation();
  const [updateCallApplicationStatus] = useUpdateCallApplicationStatusMutation();

  const statuses = responseData?.meta?.statuses || [];

  const STATUS_OPEN = statuses.find((status) => status === "Open") || "Open";
  const STATUS_COMPLETED = statuses.find((status) => status === "Completed") || "Completed";
  const STATUS_VALIDATED = statuses.find((status) => status === "Validated") || "Validated";

  const statusColorMapping = {
    [STATUS_OPEN]: "success",
    [STATUS_COMPLETED]: "primary",
    [STATUS_VALIDATED]: "warning",
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };

  const handleTableModalClose = () => {
    setShowTableModal(false); // Close the table modal
  };

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  const handleEdit = (application) => {
    setCurrentApplication(application);
    setStartDate(application.start_date ? new Date(application.start_date) : null);
    setEndDate(application.end_date ? new Date(application.end_date) : null);
    setValue("min_amnt", application.min_amnt);
    setValue("max_amnt", application.max_amnt);
    setValue("currency", application.currency);
    setValue("maturity", application.maturity);
    setValue("grace", application.grace);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleCreateNew = () => {
    setIsEditMode(false);
    setShowModal(true);
  };

  const onSubmit = async (data) => {
    data.start_date = startDate ? formatDate(startDate) : null;
    data.end_date = endDate ? formatDate(endDate) : null;
    if (startDate && endDate && endDate < startDate) {
      toast.warning(`${endDateLabel} cannot be earlier than ${startDateLabel}`, {
        theme: "colored",
      });
      return;
    }
    try {
      if (isEditMode && currentApplication) {
        await updateCallApplication({
          id: currentApplication.id,
          call_application_in: data,
        }).unwrap();
        toast.success(successUpdateMessage, { theme: "colored" });
      } else {
        const response = await createCallApplication(data).unwrap();
        toast.success(successCreateMessage, { theme: "colored" });
      }
      refetch();
      handleCloseModal();
    } catch (error) {
      toast.error(`${errorMessage} ${error.data?.detail}`, { theme: "colored" });
    }
  };

  useEffect(() => {
    if (statusUpdateTaskId) {
      const ws = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/ws/task-status/${statusUpdateTaskId}`
      );

      ws.onmessage = (event) => {
        const message = event.data;
        setTaskStatus(message);
        if (message.includes("successfully")) {
          toast.success(message, { theme: "colored" });
          ws.close();
        } else if (message.includes("failed")) {
          toast.error(message, { theme: "colored" });
          ws.close();
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        console.log("WebSocket closed");
      };

      return () => {
        ws.close();
      };
    }
  }, [statusUpdateTaskId]);

  const handleStatusAction = async (callAppId, newStatus) => {
    // Allow backend updates for both Completed and Validated statuses
    if (newStatus !== STATUS_COMPLETED && newStatus !== STATUS_VALIDATED) {
      console.log(`Status change to ${newStatus} does not trigger backend action.`);
      return;
    }

    try {
      // Send update request to the backend with the new status
      const response = await updateCallApplicationStatus({
        id: callAppId,
        new_status: newStatus,
      }).unwrap();

      // If a task ID is returned, set it for further tracking
      if (response.task_id) {
        setStatusUpdateTaskId(response.task_id);
      }
      // Refetch data to reflect the updated status
      refetch();
      toast.success(`Status updated to ${newStatus}`, { theme: "colored" });
    } catch (error) {
      // Show an error message if the update fails
      toast.error(`Failed to update status: ${error.data?.detail}`, { theme: "colored" });
    }
  };

  const getStatusBadge = (status, callAppId) => {
    const badgeColor = statusColorMapping[status] || "secondary";
    let availableStatuses = [];

    if (status === STATUS_OPEN) {
      availableStatuses = [STATUS_COMPLETED];
    } else if (status === STATUS_COMPLETED) {
      availableStatuses = [STATUS_VALIDATED];
    } else if (status === STATUS_VALIDATED) {
      availableStatuses = [];
    }

    // Render the badge with the appropriate color and dropdown for status actions
    return (
      <Dropdown>
        <Dropdown.Toggle variant="link" className="p-0">
          <SubtleBadge pill bg={badgeColor}>
            {status}
          </SubtleBadge>
        </Dropdown.Toggle>
        {availableStatuses.length > 0 && (
          <Dropdown.Menu>
            {availableStatuses.map((availableStatus) => (
              <Dropdown.Item
                key={availableStatus}
                onClick={() => handleStatusAction(callAppId, availableStatus)}
              >
                <FontAwesomeIcon icon={faCheckSquare} className="me-2 text-success" />{" "}
                {availableStatus}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    );
  };

  // New state variables for ReportSchedule modal
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [isScheduleEditMode, setIsScheduleEditMode] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState(null); // The ReportSchedule being edited
  const [scheduleDate, setScheduleDate] = useState(null); // For the DatePicker

  // Mutations for creating and updating ReportSchedule
  const [createReportSchedule] = useCreateReportScheduleMutation();
  const [updateReportSchedule] = useUpdateReportScheduleMutation();

  // Fetch ReportSchedules for the selected CallApplication
  const {
    data: reportScheduleForCallData,
    isLoading: reportScheduleDataLoading,
    error: reportScheduleError,
    refetch: refetchReportSchedules,
  } = useGetReportScheduleForCallQuery(
    {
      call_id: currentApplication?.id,
      page,
      size,
    },
    {
      enabled: !!currentApplication?.id,
    }
  );

  // Function to handle opening the table modal to show ReportSchedules
  const handleShowTableModal = (application) => {
    setCurrentApplication(application); // Set the selected CallApplication
    setShowTableModal(true); // Open the modal to display ReportSchedules
  };

  // Function to handle opening the modal for creating a new ReportSchedule
  const handleCreateNewReportSchedule = () => {
    setIsScheduleEditMode(false); // Set mode to create
    setCurrentSchedule(null); // No existing schedule
    setScheduleDate(null); // Reset DatePicker
    setShowScheduleModal(true); // Open the modal
  };

  // Function to handle opening the modal for editing an existing ReportSchedule
  const handleEditReportSchedule = (schedule) => {
    setIsScheduleEditMode(true); // Set mode to edit
    setCurrentSchedule(schedule); // Set the schedule to be edited
    setScheduleDate(new Date(schedule.schedule_date)); // Set DatePicker to existing date
    setShowScheduleModal(true); // Open the modal
  };

  // Function to handle closing the ReportSchedule modal
  const handleCloseScheduleModal = () => {
    setShowScheduleModal(false);
    setScheduleDate(null);
    setCurrentSchedule(null);
  };

  // Function to handle creating or updating a ReportSchedule
  const handleSaveReportSchedule = async () => {
    const requestBody = {
      schedule_date: scheduleDate ? formatDate(scheduleDate) : null,
      call_application_id: currentApplication.id, // Associate with current CallApplication
    };

    try {
      if (isScheduleEditMode && currentSchedule) {
        // Update existing ReportSchedule
        await updateReportSchedule({
          id: currentSchedule.id,
          reportScheduleData: requestBody,
        }).unwrap();
        toast.success("Report Schedule updated successfully!", { theme: "colored" });
      } else {
        // Create new ReportSchedule
        await createReportSchedule(requestBody).unwrap();
        toast.success("Report Schedule created successfully!", { theme: "colored" });
      }
      refetchReportSchedules(); // Refresh the list
      handleCloseScheduleModal(); // Close the modal
    } catch (error) {
      toast.error("Failed to save Report Schedule", { theme: "colored" });
    }
  };

  const stripedTable = (
    <Table striped bordered>
      <thead>
        <tr>
          <th>ID</th>
          <th>Schedule Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {/* Check if data is loading or if there's an error */}
        {reportScheduleDataLoading ? (
          <tr>
            <td colSpan="3">
              <Spinner animation="border" />
            </td>
          </tr>
        ) : reportScheduleError ? (
          <tr>
            <td colSpan="3">Error loading schedules.</td>
          </tr>
        ) : (
          reportScheduleForCallData?.data?.items.map((schedule) => (
            <tr key={schedule.id}>
              <td>{schedule.id}</td>
              <td>{schedule.schedule_date}</td>
              <td className="text-center">
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => handleEditReportSchedule(schedule)} // Update schedule on button click
                >
                  <FontAwesomeIcon icon="edit" />
                </Button>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );

  // Memoizing the modified data
  const modifiedData = useMemo(() => {
    return responseData
      ? responseData.data.items.map((item) => ({
          ...item,
          amnt: `${item.min_amnt}-${item.max_amnt}`, // Combining min_amnt and max_amnt
        }))
      : [];
  }, [responseData]);

  const columns = [
    {
      accessorKey: "id",
      header: id,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "status",
      header: callApplicationStatus,
      cell: ({ row: { original } }) => getStatusBadge(original.status, original.id),
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "start_date",
      header: startDateLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "end_date",
      header: endDateLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "amnt",
      header: amountLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "currency",
      header: currencyLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "maturity",
      header: loanTenor,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      accessorKey: "grace",
      header: graceLabel,
      meta: {
        headerProps: { className: "text-900" },
      },
    },
    {
      id: "action",
      header: "Action",
      cell: ({ row: { original } }) => {
        const isEditVisible =
          original.status !== STATUS_COMPLETED && original.status !== STATUS_VALIDATED;

        // If the status is "Validated", show the calendar icon instead of the edit button
        if (original.status === STATUS_VALIDATED) {
          return (
            <OverlayTrigger overlay={<Tooltip>View Calendar</Tooltip>}>
              <div className="d-flex justify-content-center">
                <Button variant="link" size="sm" onClick={() => handleShowTableModal(original)}>
                  {/* <FontAwesomeIcon icon={faCalendarDay} /> */}
                  <CalendarDaysIcon />
                </Button>
              </div>
            </OverlayTrigger>
          );
        }

        // Otherwise, show the edit button for non-completed and non-validated statuses
        return isEditVisible ? (
          <OverlayTrigger overlay={<Tooltip>{editTooltipText}</Tooltip>}>
            <div className="d-flex justify-content-center">
              <Button variant="link" size="sm" onClick={() => handleEdit(original)}>
                <FontAwesomeIcon icon="edit" />
              </Button>
            </div>
          </OverlayTrigger>
        ) : null;
      },
      meta: {
        headerProps: { className: "text-900 text-start" },
      },
    },
  ];

  const table = useAdvanceTable({
    data: modifiedData,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10,
  });

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (error) {
    return <div>{loadingText}</div>;
  }

  return (
    <>
      <FalconComponentCard>
        <FalconComponentCard.Header
          title={`${modelName}s`}
          light={false}
          className="border-bottom border-200"
        ></FalconComponentCard.Header>
        <FalconComponentCard.Body>
          <AdvanceTableProvider {...table}>
            <Row className="center mt-1 mb-4">
              <Col xs="auto" sm={6} lg={4} className="text-start">
                <AdvanceTableSearchBox />
              </Col>
              <Col xs="auto" sm={6} lg={4} className="text-end ms-auto">
                <Button
                  type="button"
                  variant="falcon-default"
                  size="sm"
                  className="ms-2"
                  onClick={handleCreateNew}
                >
                  <FontAwesomeIcon icon="plus" className="me-2" />
                  {newButtonText}
                </Button>
              </Col>
            </Row>
            <AdvanceTable
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
            </div>
          </AdvanceTableProvider>
        </FalconComponentCard.Body>
      </FalconComponentCard>

      {/* Modal for editing or creating applications */}
      <Modal show={showModal} onHide={handleCloseModal} centered className="call-application-modal">
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>{startDateLabel}</Form.Label>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
              {errors.start_date && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{endDateLabel}</Form.Label>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
              {errors.end_date && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{minAmountLabel}</Form.Label>
              <Form.Control type="number" {...register("min_amnt", { required: true })} />
              {errors.min_amnt && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{maxAmountLabel}</Form.Label>
              <Form.Control type="number" {...register("max_amnt", { required: true })} />
              {errors.max_amnt && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{currencyLabel}</Form.Label>
              <Form.Control
                type="text"
                {...register("currency", { required: true })}
                defaultValue="Nepali rupee"
                readOnly
              />
              {errors.currency && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{loanTenor}</Form.Label>
              <Form.Control type="number" {...register("maturity", { required: true })} />
              {errors.maturity && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{graceLabel}</Form.Label>
              <Form.Control type="number" {...register("grace", { required: true })} />
              {errors.grace && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Modal.Footer className="call-application-modal">
              <Button variant="secondary" onClick={handleCloseModal}>
                {closeButtonText}
              </Button>
              <Button variant="primary" type="submit" disabled={isUpdating || isCreating}>
                {buttonText}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for showing the striped table */}
      <Modal show={showTableModal} onHide={handleTableModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reports Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end mb-3">
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              onClick={handleCreateNewReportSchedule} // Open the create report schedule modal
            >
              <FontAwesomeIcon icon="plus" className="me-2" />
              New
            </Button>
          </div>
          {stripedTable}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTableModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for creating a new report schedule */}
      <Modal show={showScheduleModal} onHide={handleCloseScheduleModal} right>
        <Modal.Header closeButton>
          <Modal.Title>Create New Report Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Schedule Date</Form.Label>
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => setScheduleDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
              {!scheduleDate && (
                <div className="invalid-feedback d-block">This field is required</div>
              )}
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseScheduleModal}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveReportSchedule} disabled={isCreating}>
                {isScheduleEditMode ? "Update Schedule" : "Create Schedule"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CallApplication;
