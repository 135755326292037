// src/services/applicationService.js
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../authorization/authService";

export const applicationApi = createApi({
  reducerPath: "applicationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Application", "SelfDeclarationTemplate", "EligibilityValue"],
  endpoints: (builder) => ({
    getApplications: builder.query({
      query: (filters) => ({
        url: "/application/list",
        method: "GET",
        params: filters,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Application", id })),
              { type: "Application", id: "LIST" },
            ]
          : [{ type: "Application", id: "LIST" }],
    }),

    getSelfDeclarationTemplates: builder.query({
      query: () => ({
        url: "/application/self_declaration_templates",
        method: "GET",
      }),
      providesTags: ["SelfDeclarationTemplate"],
    }),

    getEligibilityValues: builder.query({
      query: () => ({
        url: "/application/eligibility",
        method: "GET",
      }),
      providesTags: ["EligibilityValue"],
    }),

    createApplicationWithSelfDeclaration: builder.mutation({
      query: (data) => ({
        url: "/application/full",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Application"],
    }),

    returnToBank: builder.mutation({
      query: ({ id, data }) => ({
        url: `/application/return_to_bank/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Application", id }],
    }),

    updateApplication: builder.mutation({
      query: ({ id, data }) => ({
        url: `/application/update/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Application", id }],
    }),

    deleteApplication: builder.mutation({
      query: (id) => ({
        url: `/application/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Application", id }],
    }),

    getApplicationById: builder.query({
      query: (id) => ({
        url: `/application/detail/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Application", id }],
    }),

    getSubmittedApplications: builder.query({
      query: ({ show_all }) => ({
        url: "/application/submitted_applications",
        method: "GET",
        params: { show_all },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "Application", id })),
              { type: "Application", id: "LIST" },
            ]
          : [{ type: "Application", id: "LIST" }],
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetSelfDeclarationTemplatesQuery,
  useGetEligibilityValuesQuery,
  useCreateApplicationWithSelfDeclarationMutation,
  useUpdateApplicationMutation,
  useDeleteApplicationMutation,
  useGetApplicationByIdQuery,
  useViewDocumentQuery,
  useGetSubmittedApplicationsQuery,
  useReturnToBankMutation,
} = applicationApi;
