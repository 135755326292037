import React from "react";
import { useSelector } from "react-redux";

import CustomerLayout from "./CustomerLayout";
import PortalAdminLayout from "./PortalAdminLayout";
import ApplicationSpecialistLayout from "./ApplicationSpecialistLayout";
import RoleTabNavigation from "./RoleTabNavigation";

import { PORTAL_ADMINISTRATOR, APPLICATIONS_SPECIALIST } from "features/constants/roles";

const MainLayoutSelector = () => {
  const { user } = useSelector((state) => state.auth);

  if (user) {
    const tabs = [];

    // Check if the user is a Portal Administrator
    if (user.roles.some((role) => role.name === PORTAL_ADMINISTRATOR)) {
      tabs.push({
        name: PORTAL_ADMINISTRATOR,
        component: <PortalAdminLayout />,
      });
    }

    // Check if the user is an Applications Specialist
    if (user.roles.some((role) => role.name === APPLICATIONS_SPECIALIST)) {
      tabs.push({
        name: APPLICATIONS_SPECIALIST,
        component: <ApplicationSpecialistLayout />,
      });
    }

    // If the user has at least one role, render the tab navigation
    if (tabs.length > 0) {
      return <RoleTabNavigation tabs={tabs} />;
    }

    // If the user has no specific roles, default to CustomerLayout
    return <CustomerLayout />;
  }

  return null; // Or a fallback layout or loading indicator
};

export default MainLayoutSelector;
